<template>
  <div class="wameed-dashboard-page-content mb-5">
    <section class="wameed-dashboard-page-content_body">


      <b-row class="no-gutters px-2">
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="featureRequestServices"
          >
            <b-badge
                v-if="requestsCounts.feature>0"
                pill
                variant="light-secondary"
                :class="'border-secondary new-request-text'"
                class="text-reg-12 py-0 px-3 top-badge"
            >{{  requestsCounts.feature +" " +$t('common.new_request') }}</b-badge>

            <b-avatar variant="light-main" class="my-4" rounded size="60">
            <request-feature-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 mt-4 ">
              {{ $t('requests.feature.title') }}
            </h4>
            <h4 class="h-panel__text text-reg-15 mt-1 mb-3 text-center">
              {{ $t('requests.feature.desc') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="providerRequestServices"
          >
            <b-badge
                v-if="requestsCounts.active> 0"
                pill
                variant="light-secondary"
                :class="'border-secondary new-request-text'"
                class="text-reg-12 py-0 px-3 top-badge"
            >{{  requestsCounts.active +" " +$t('common.new_request') }}</b-badge
            >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <request-active-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 mt-4 ">
              {{ $t('requests.active.title') }}
            </h4>
            <h4 class="h-panel__text text-reg-15 mt-1 mb-3 text-center">
              {{ $t('requests.active.desc') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0 ">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="cancelRequestServices"
          >
            <b-badge
                v-if="requestsCounts.cancel>0"
                pill
                variant="light-secondary"
                :class="'border-secondary new-request-text'"
                class="text-reg-12 py-0 px-3 top-badge"
            >{{  requestsCounts.cancel +" " +$t('common.new_request') }}</b-badge>
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <request-cancel-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 mt-4 ">
              {{ $t('requests.cancel.title') }}
            </h4>
            <h4 class="h-panel__text text-reg-15 mt-1 mb-3 text-center">
              {{ $t('requests.cancel.desc') }}
            </h4>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  components: {},
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      requestsCounts: 'getRequestsCounts',
    }),
  },
  mounted() {
    this.$store.dispatch('loadRequestsCounts');
  },

  methods: {

    providerRequestServices() {
      this.$router.push({ name: 'providerRequestServices', params: { lang: this.$i18n.locale } });
    },
    featureRequestServices() {
      this.$router.push({ name: 'featureRequestServices', params: { lang: this.$i18n.locale,type:"feature" } });
    },
    cancelRequestServices() {
      this.$router.push({ name: 'featureRequestServices', params: { lang: this.$i18n.locale,type:"cancel" } });
    },



  },
};
</script>
 
